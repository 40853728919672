.text {
  color: #161c2d;
  font-size: 1rem;
  margin: 0 0 24px;
}
.form-control {
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
}

.form-control label {
  color: black;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-control input {
  appearance: none;
  font-size: 1rem;
  border-radius: 6px;
  height: 30px;
  padding: 6px 12px;
  background-color: white;
  border: 1px solid #dee2e6;
}

.form-control input:focus {
  outline: none;
}
.form-control input:-webkit-autofill{
  -webkit-box-shadow:  0 0 0 1000px #F5F7FA inset !important;
}

.button {
  background-color: var(--pacific);
  border-style: none;
  color: white;
  font-size: 1.2rem;
  height: 42px;
  cursor: pointer;
  border-radius: 6px;
}
.button:hover{
  background-color: var(--dark-blue);
}
.link {
  color: var(--pacific);
  margin-top: 10px;
}
.nav-tabs{
  margin: 0 0 16px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.nav-tabs .nav-item{
  list-style: none;
}
.nav-tabs button.active{
  border-bottom: 2px solid var(--pacific);
  border-radius: 0;
  font-weight: bold;
}
