@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap");

.hero {
  background-image: url('../../img/bg-hero.jpeg');
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  width: 100%;
  background-repeat: repeat;
  background-position: 0 0;
  background-size: auto 100%;
  animation: slideleft 140s infinite linear;
  padding: 0;
}
.hero::before {
  content: '';
  background: rgba(25,28,30,.4);
  background-image: linear-gradient(to top,rgba(3,33,48,.6) 0,rgba(60,72,88,0) 60%,rgba(3,33,48,.8) 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
@keyframes slideleft {
  from {
    background-position:0 0;
  }
  to {
    background-position:10000px 0;
  }
}
@keyframes slideleft {
  from {
    background-position:0 0;
  }
  to {
    background-position:10000px 0;
  }
}

.container {
  margin: auto;
  position: relative;
  width: 90%;
}
.logo {
  position: absolute;
}
.logo img {
  height: 80px;
  padding-top: 50px;
}

.left{
  padding-top: 180px;
}
.left, .right {
  display: flex;
  align-items: center;
}
.right{
  justify-content: center;
  padding-bottom: 50px;
}
.form {
  background: white;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 30px;
  width: 350px;
}
.text{
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 8vw;
  line-height: 1;
  margin: 0 0 20px;
}
.text span {
  font-size: 6vw;
  font-weight: normal;
  display: block;
  margin-top: 8px;
}
.link {
  margin-top: 10px;
  color: var(--pacific);
}

.link:link, .link:visited {
  color: black;
}
@media (min-width: 992px){
  .container {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
  }
  .left{
    padding-top: 0;
  }
  .left, .right {
    min-height: 100vh;
  }
  .right{
    padding-bottom: 0;
  }
  .text{
    font-size: 3.5vw;
  }
  .text span {
    font-size: 2.5vw;
  }
}
@media (min-width: 1200px){
  .container {
    max-width: 1140px;
  }
}
