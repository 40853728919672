.bg {
  background-color: #064ACB;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logo{
  position: absolute;
  width: 400px;
  max-width: 90%;
  top: 100px
}
.load{
  margin: 120px 0 0;
  width: 180px;
}
.text{
  font-size: 1.3rem;
  color: white;
  margin: 0;
}
