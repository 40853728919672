body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --red-lucky: #FF3036;
  --dark-red: #D23036;
  --light-red: #DA6653;
  --pacific: #041DDD;
  --dark-blue: #0400D2;
  --light-blue: #042FFF;
  --aqua-green: #55FFE2;
  --cyan: #009FE3;
  --yellow: #FFDE00;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #edeff5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] { -moz-appearance:textfield; }

.breadcrumb-custom{
  height: 40px;
  line-height: 40px !important;
  background-color: #7e87ea;
  margin-top: -25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  width: 100%;
}
.breadcrumb-custom a{
  color: white;
  font-size: 0.875rem;
  text-decoration: underline;
}
.breadcrumb-custom p{
  font-size: 0.875rem;
}
/*Think tank */
/*menu*/
.think-tank .menu{
  display: flex;
  justify-content: space-around;
  border-bottom: 5px solid #1e74f4;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.think-tank .menu button{
  color: #1e74f4;
}
/*section main*/
.featured-grid-all {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 245px;
  grid-gap: 20px;
}
.post-mansory .featured-box:first-child .post-filter-link:before {
  background-image: linear-gradient(to bottom,transparent, #1e74f4);
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 66%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  opacity: 1;
}
.featured-grid-all .entery-category-fly {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  padding: 5px 10px 10px;
}
.featured-grid-all .entery-category-fly .entry-title{
  margin: 6px 0;
}
.featured-box {
  position: relative;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px 1px rgb(0 0 0 / 7%);
}
.featured-box .post-filter-link img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured-box .post-date{
  text-transform: capitalize;
}
.post-mansory .featured-box:first-child{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.post-grid{
  grid-auto-rows: 280px;
}
.post-mansory .featured-box:first-child .entry-title{
  font-size: 21px;
  line-height: 1.3em;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-mansory .featured-box:first-child .post-snip {
  color: #eee;
}
.featured-box:nth-child(n+2), 
.post-grid .featured-box{
  display: grid;
}
.featured-box:nth-child(n+2) .entry-title, 
.post-grid .featured-box .entry-title{
  height: 68px;
  overflow: hidden;
  font-size: 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.3em;
  padding: 5px 0;
}
.featured-box:nth-child(n+2) .post-filter-inner{
  height: 110px;
}
.post-grid .featured-box .post-filter-inner{
  height: 146px;
}
@media screen and (max-width: 1024px){
  .featured-grid-all {
    grid-gap: 12px;
  }
}
@media screen and (max-width: 880px){
  .featured-grid-all {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
  .featured-grid-all .entery-category-fly {
    left: 0;
    width: 100%;
  }
  .post-mansory .featured-box:first-child{
    grid-column-end: 3;
    grid-row-end: 2;
  }
}
span.post-author:after {
  position: relative;
  display: inline-block;
  content: "-";
  margin: 0 3px;
}
span.post-author:not(.entery-category-fly span.post-author) {
  color: #1e74f4;
}
.posts-tag{
  display: flex;
  flex-wrap: wrap;
}
.post-tag {
  white-space: nowrap;
  height: 18px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1px 8px 0;
  border-radius: 2px;
  z-index: 2;
  margin-right: 10px;
}
.post-tag:first-child{
  background-color: #1e74f4;
}
.post-tag:last-child{
  background: white;
  border: 1px solid #1e74f4;
  color: #1e74f4;
}
span.post-author {
  font-weight: 500;
}
.entery-category {
  position: relative;
  overflow: hidden;
  padding: 10px;
}
@media screen and (max-width: 880px){
  .entery-category {
    padding: 6px;
  }
}

.post-snip {
  color: #707070;
  font-size: 12px;
}
.think-tank{
  background: #f4f4f4;
  min-height: 100vh;
}
.think-tank .entry-content a, .entry-title{
  color: #333;
  text-decoration: none;
}
.simple-viewmore{
  color: #333; 
}
.think-tank .post-image{
  max-width: 100%;
}
/*section second*/
.xXio-raw{
  margin-top: 48px;
}
.xXio-raw .widget-title {
  margin: 0 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xXio-raw .widget-title h3 {
  height: 32px;
  font-size: 13px;
  color: #3e3e3e;
  text-transform: uppercase;
  line-height: 32px;
  border-left: 3px solid #1e74f4;
  padding: 0 12px;
  margin: 0;
}
.xXio-raw .widget-title a{
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}
.xXio-raw .entery-category .entry-title {
  font-size: 17px;
  height: 38px;
  overflow: hidden;
  margin: 10px 0;
  line-height: 18px;
}

/*Post*/
.post-inner-area {
  position: relative;
  width: 100%;
  padding: 25px 30px 30px;
  box-sizing: border-box;
  margin: 0 0 10px;
  background: #fff;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 0.08);
  border-radius: 4px;
}
.item-post h1.entry-title {
  color: #3e3e3e;
  text-align: left;
  font-size: 25px;
  line-height: 1.5em;
  font-weight: 600;
  position: relative;
  display: block;
  margin: 0 0 15px;
  padding: 0;
}
.all-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 12px;
  border-bottom: 1px solid rgb(100 100 100 / 0.12);
}
.post-inner-data.flex {
  display: flex;
  align-content: center;
}
span.author-image {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
}
span.author-image img {
  border-radius: 50%;
}
.post-inner-username {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
}
span.post-author-times {
  position: relative;
  display: block;
  font-size: 12px;
  color: #3e3e3e;
}
.post-times {
  position: relative;
  overflow: hidden;
  margin: 4px 0 0;
}
.post-times span.post-date {
  position: relative;
  font-size: 12px;
  color: #707070;
}
blockquote {
  position: relative;
  font-style: italic;
  float: left;
  color: #404040;
  margin-left: 0;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  background: rgb(72 72 72 / 0.09);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.item-post .post-body {
  width: 100%;
  color: #505050;
  font-size: 15px;
  line-height: 1.8em;
  padding: 0;
  margin: 32px 0 0;
}
.feed-share {
  position: relative;
  clear: both;
  overflow: hidden;
  line-height: 0;
  margin: 0 0 30px;
}
ul.xxXio-share {
  position: relative;
}
.xxXio-share li {
  float: left;
  overflow: hidden;
  margin: 5px 5px 0 0;
  list-style: none;
}
.social-front-hover a.facebook, .social-front-hover a.facebook-f {
  background: #3b5999;
}
.xxXio-share li a {
  display: block;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgb(110 110 110 / 0.33);
  border-radius: 3px;
  text-decoration: 0;
}
.social-front-hover a.twitter {
  background: #00acee;
}
.social-front-hover a.whatsapp {
  background: #3fbb50;
}
.card-text-icon{
  margin: 8px;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  gap: 12px;
  border: 1px solid #ccc;
}