body {
    background-color: #DEE7FF;
}
.right {
    width: 320px;
    margin-top: 42px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    background-image:linear-gradient(rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52)), url('../img/edificios.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    border-radius: 3px;
    background-color: white;
}

.logo {
    text-align: center;
    padding-top: 35px;
}
.logo img {
    width: 179px;
}
.controls {
    margin-top:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
input {
    border: none;
}
input:focus {
    outline: none;
}
.textinput {
    width: 244px;
    height: 36px;
    border-width: 1px;
    border-style: outset;
    border-color: #708496;
    border-radius: 3px;
    display: block;
    margin-bottom: 21px;
    padding-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}
.textinput::placeholder {
    color: #A4BFD8;
}
.button {
    margin-top: 21px;
    margin-bottom: 30px;
    width: 244px;
    height: 39px;
    border-radius: 5px;
    background-color: #0A0E12;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.link {
    font-size: 0.8rem;
}
.link, .link:visited, .link:hover, .link:active {
    color: red;
}
.error {
    color: red;
}
@media (min-width: 660px)
{
    .main {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    .container {
        width: 640px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        box-shadow: 4px 4px 7px -1px rgba(0, 0, 0, 0.25);
    }
    .left {
        width: 320px;
        background-image:url(../img/edificios.png);
        background-size:cover;
        background-position: center;
    }
    .right {
        width: 320px;
        background: none;
        background-color: white;
        margin: 0;
    }
}