.button {
    margin-top: 1em;
    width: 100%;
    height: 2.5em;
    border-radius: 10px;
    border: solid 1px #112091;
    padding-left: 1em;
    margin-bottom: 1em;
}

.button:focus {
    outline: none;
    background-color: #7e87ea;
    color: white;
}

.button:focus::placeholder {
    color: lightcyan;
}

.info {
    font-size: 0.8rem;
    padding-left: 0.7em;
}